<template>
  <div>
    <span v-if="value !== null && value !== ''">{{ value|format('L') }}</span>
    <span v-else> - </span>
  </div>
</template>

<script>
export default {
  name: 'TemplateDate',
  props: ['value'],
};
</script>
