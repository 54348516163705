





































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Routing from 'fos-routing';
import _ from 'lodash';

import { lazyInject } from '@/ioc/container';
import SERVICE_IDENTIFIER from '@modules/professional-risk/ioc/identifiers';
import RequestSaveForm from '@/utils/RequestSaveForm';

import { ActionInterface, PrintActionInterface } from '@/types/breadcrumb';
import { ActionPlanEnumName } from '@modules/professional-risk/types/enum';

import Layout from '@/layouts/LayoutDefault.vue';
import TemplateDate from '@/templates/TemplateDate.vue';

import ProActionPlanFilter
  from '@modules/professional-risk/components/form/ProActionPlanFilter.vue';

import ProFolder from '@models/proRisk/ProFolder';
import ActionPlan, { ActionPlanStatus } from '@models/proRisk/ActionPlan';
import { RiskServiceInterface } from '@modules/professional-risk/services/RiskServiceInterface';
import { PaginatedData } from '@/types/paginatedData';

const riskProModule = namespace('riskPro');
const actionPlanRepository = namespace('repositories/proActionPlan');
const riskCharacterRepository = namespace('repositories/riskCharacter');

const flag1 = require('#/images/professional_risks/flag_1.png');
const flag2 = require('#/images/professional_risks/flag_2.png');
const flag3 = require('#/images/professional_risks/flag_3.png');

@Component({
  components: {
    TemplateDate,
    ProActionPlanFilter,
    Layout,
  },
})
export default class ActionPlanView extends Vue {
  @riskProModule.Getter('getActiveProFolder')
  proFolder!: ProFolder | null;

  @actionPlanRepository.Getter('getAllOrder')
  actionPlansOrder!: (order: { col: string, direction: 'asc' | 'desc' }) => ActionPlan[];

  @actionPlanRepository.Action('search')
  search!: (payload: { folder: ProFolder, filters: any }) => Promise<PaginatedData<ActionPlan>>;

  @actionPlanRepository.Action('delete')
  delete!: (id: number) => Promise<void>;

  @riskCharacterRepository.Action('findAll')
  fetchRiskCharacter!: () => Promise<void>;

  @lazyInject(SERVICE_IDENTIFIER.RiskServiceInterface)
  riskService!: RiskServiceInterface;

  actionPlans: ActionPlan[] = [];
  loading: boolean = false;
  actionPlanStatusEnum = ActionPlanEnumName;

  tableProps: any = {
    border: true,
    stripe: true,
    defaultSort: { prop: 'risk.character.label', order: 'ascending' },
    rowClassName: this.checkAbandoned,
  };

  paginationProps: any = {
    pageSizes: [10, 20, 50, 100],
    layout: 'prev, pager, next, jumper, ->, total, slot',
  };

  filters: any = {
    limit: 10,
    page: 1,
    order: '',
    orderBy: '',
  };
  totalResult = 0;

  flags: Record<number, string> = {
    1: flag1,
    2: flag2,
    3: flag3,
  };

  get pdfLink() {
    if (this.proFolder) {
      const params = { ..._.omit(this.filters, ['limit', 'page']), id: this.proFolder.id };

      return Routing.generate('pdf_pro_folder_action_plan', params);
    }

    return '';
  }

  get actions(): ActionInterface[] {
    return this.isGranted('pro-risks-action-plan', 2) ? [
      {
        label: 'Ajouter',
        route: 'pro-risks-action-plan-create',
      },
    ] : [];
  }

  get printAction(): PrintActionInterface | null {
    return {
      href: this.pdfLink,
    };
  }

  @Watch('filters', { immediate: true, deep: true })
  onFilterChange() {
    if (this.proFolder) {
      this.load();
    }
  }

  async load() {
    if (this.proFolder && !this.loading) {
      this.loading = true;
      const data = await this.search({ folder: this.proFolder, filters: this.filters });
      this.totalResult = data.meta.total;
      const direction: 'asc' | 'desc' = this.filters.order.toLowerCase() === 'asc' ? 'asc' : 'desc';
      this.actionPlans = this.actionPlansOrder({ col: this.filters.orderBy as string, direction });
      this.loading = false;
    }
  }

  getStatusFlagUrl(status: ActionPlanStatus): string | undefined {
    return this.flags[status];
  }

  checkAbandoned(payload: { row: ActionPlan }) {
    const { row } = payload;
    if (row.abandoned) {
      return 'action-abandon';
    }

    return '';
  }

  sortChange(payload: { type: string, sort: any }) {
    const { type, sort } = payload;
    if (type !== 'sort') {
      return;
    }

    if (sort.order !== null) {
      this.filters.order = sort.order === 'ascending' ? 'ASC' : 'DESC';
      this.filters.orderBy = sort.prop;
    } else {
      this.filters.order = '';
      this.filters.orderBy = '';
    }
  }

  handleDeleteBtn(id: number) {
    this.$confirm('Confirmer la suppression du plan d\'action.').then(async (result) => {
      if (result === 'confirm') {
        this.loading = true;
        this.handleDelete(id)
          .then(async (response) => {
            await this.load();
            return response;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    });
  }

  @RequestSaveForm({
    validMsg: 'Suppression effectuée.',
    errorMsg: 'Erreur lors de la suppression de votre plan d\'action.',
  })
  handleDelete(id: number) {
    return this.delete(id);
  }

  created() {
    this.fetchRiskCharacter();
  }
}
