import { createDecorator } from 'vue-class-component';

export interface ConfigRequestSaveForm {
  loadingDataName?: string,
  duration?: number,
  validMsg?: string,
  errorMsg?: string,
}

export default function RequestSaveForm(config?: ConfigRequestSaveForm) {
  return createDecorator((options, key) => {
    const conf = config || {};
    if (options.methods) {
      const originalMethod = options.methods[key];
      options.methods[key] = function wrappedMethod(...args) {
        if (conf.loadingDataName && typeof (this as any)[conf.loadingDataName] === 'boolean') {
          (this as any)[conf.loadingDataName] = true;
        }

        return originalMethod.apply(this, args)
          .then((response: Response) => {
            this.$message({
              type: 'success',
              message: conf.validMsg || 'La sauvegarde a bien été effectuée',
              duration: conf.duration || 5000,
              showClose: true,
              center: true,
            });

            return response;
          })
          .catch((error: Error) => {
            this.$message({
              type: 'error',
              message: conf.errorMsg || error.message,
              duration: conf.duration || 5000,
              showClose: true,
              center: true,
            });

            throw error;
          })
          .finally(() => {
            if (conf.loadingDataName && typeof (this as any)[conf.loadingDataName] === 'boolean') {
              (this as any)[conf.loadingDataName] = false;
            }
          });
      };
    }
  });
}
