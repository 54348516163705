








































import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import StructureFilter from '@/components/form/StructureFilter.vue';
import FilterComponent from '@/components/form/FilterComponent';

import { ActionPlanEnumName } from '@modules/professional-risk/types/enum';

import RiskCharacter from '@models/proRisk/RiskCharacter';

const riskCharacterRepository = namespace('repositories/riskCharacter');

@Component({
  components: { StructureFilter },
})
export default class ProActionPlanFilter extends FilterComponent {
  @riskCharacterRepository.Getter('getAll')
  riskCharacters!: RiskCharacter[];

  actionPlanStatusEnum = ActionPlanEnumName;

  filters = {
    riskCharacter: '',
    affectation: '',
    driver: '',
    state: '',
  };
}
